<template>
  <v-container fluid>
    <HistoryGlobalMetrics />
  </v-container>
</template>

<script>
import HistoryGlobalMetrics from '@/components/organisms/HistoryGlobalMetrics'

export default {
  name: 'GlobalMetrics',
  components: {
    HistoryGlobalMetrics,
  },
}
</script>
