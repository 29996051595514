<template>
  <v-row dense class="mb-4 mx-1">
    <v-col cols="12">
      <v-row dense>
        <v-col>
          <h1>{{ $t('HistoryGlobalMetrics') }}</h1>
        </v-col>
      </v-row>
      <v-col v-if="loading">
        <Loader class="pt-16 pb-10" />
      </v-col>
      <v-col v-else class="pa-0">
        <v-divider class="my-5" />
        <v-card class="elevation-0 pa-3 pa-md-5 card_bg">
          <v-row class="mb-5" dense justify="center">
            <v-col cols="12" sm="6" md="6" justify="left">
              <v-btn-toggle
                class="d-contents v-btn-group v-btn-group--2 toggle-controls"
                dense
                mandatory
              >
                <template v-for="(o, idx) in btnChartTypeGroupDetails">
                  <v-btn
                    :key="idx"
                    class="body-2 uncapitalize"
                    @click="onChangeChartType(o.type)"
                  >
                    {{ $t(o.name) }}
                  </v-btn>
                </template>
              </v-btn-toggle>
            </v-col>
            <v-col
              v-if="!isCustomLegendsVisible"
              cols="12"
              sm="6"
              md="6"
              class="text-center"
            >
              <v-btn-toggle
                class="toggle-controls v-btn-group v-btn-group--5 d-none d-lg-flex"
                dense
                mandatory
              >
                <v-btn
                  v-for="(btn, idx) in btnGroupDetails"
                  :key="idx"
                  :input-value="btn.selected"
                  :disabled="chartType < 1 ? false : true"
                  class="body-2 uncapitalize"
                  @click="onChangeView(idx)"
                >
                  <span>{{ btn.name }}</span>
                </v-btn>
              </v-btn-toggle>

              <Menu
                :button-size="`primary no-hover-bg uncapitalize d-flex d-lg-none`"
                :is-disabled="chartType < 1 ? false : true"
                :selected-text="selected_tab.name"
                :list-items="btnGroupDetails"
                key-item="name"
                @click="onChangeViewMenu($event)"
              />
            </v-col>
            <v-col cols="12" sm="6" md="6" justify="right">
              <v-btn-toggle
                class="float-right v-btn-group v-btn-group--4 toggle-controls d-none d-lg-flex"
                dense
                mandatory
              >
                <v-btn
                  v-for="(btn, idx) in common.daysGroup"
                  :key="idx"
                  :input-value="btn.selected"
                  class="body-2 uncapitalize px-2"
                  @click="onChangeDays(btn.name)"
                >
                  <span>{{ btn.name }}</span>
                </v-btn>
              </v-btn-toggle>

              <Menu
                :button-size="`primary no-hover-bg uncapitalize d-flex d-lg-none`"
                :selected-text="selected_days.name"
                :list-items="common.daysGroup"
                key-item="name"
                @click="onChangeDaysMenu($event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <Loader v-if="chartLoading" :height="`500px`" />
              <template v-else>
                <div v-if="isCustomLegendsVisible && chartType < 2" class="d-flex f-16 flex-wrap justify-center">
                  <v-checkbox
                    v-for="(legend, index) in legends"
                    :key="index"
                    v-model="selected_legends"
                    class="mx-2"
                    :color="legend.color"
                    multiple
                    :hide-details="index !== legends.length - 1"
                    :value="legend.name"
                    @change="onLegendChange($event, index)"
                  >
                    <template v-slot:label>
                      <div
                        class="custom-legend f-14"
                        :style="{ background: legend.color }"
                      >
                      </div>
                      <span class="mx-1 f-14">{{ legend.name }}</span>
                    </template>
                  </v-checkbox>
                </div>
                <SimpleChart v-if="chartType < 2" :is-multiaxis="true" :chart-data="simpleDataset" />
                <template v-else>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <SimpleChart :chart-data="[simpleDataset[0]].flat()" />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <SimpleChart :chart-data="[simpleDataset[1]].flat()" />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <SimpleChart :chart-data="[simpleDataset[2]].flat()" />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <SimpleChart :chart-data="[simpleDataset[3]].flat()" />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <SimpleChart :chart-data="[simpleDataset[4]].flat()" />
                    </v-col>
                  </v-row>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { get, call, commit } from 'vuex-pathify'
import Loader from '@/components/molecules/Loader'
import Menu from '@/components/atoms/Menu'
import helper from '@/utils/helper'
import SimpleChart from '@/components/organisms/chart/HistoryGlobalMetricsChart'
import constants from '@/constants/constants'
import _ from 'lodash'
import common from '@/shared/common'

export default {
  name: 'HistoryGlobalMetrics',
  components: {
    Loader,
    SimpleChart,
    Menu,
  },
  data: () => ({
    common: common,
    helper: helper,
    loading: true,
    assets: [],
    chartLoading: true,
    viewType: 0,
    data_source: [],
    historyDataset: [],
    days: '1D',
    selected_legends: ['Volume'],
    isCustomLegendsVisible: true,
    chartType: 1,
    simpleDataset: [],
    multiDataset: [],
    selected_tab: { name: 'Volume', icon: 'mdi-chart-box-outline', value: 'volume' },
    selected_days: { type: '1D' },
    groupBtnDetails: '',
    btnGroupDetails: [
      { name: 'Volume', icon: 'mdi-chart-box-outline', value: 'volume', selected: true },
      { name: 'Market Cap', icon: 'mdi-chart-gantt', value: 'marketcap', selected: false },
      { name: 'BTC Dominance', icon: 'mdi-chart-pie', value: 'btcdominance', selected: false },
      { name: 'ETH Dominance', icon: 'mdi-chart-arc', value: 'ethdominance', selected: false },
      { name: 'Cryptos', icon: 'mdi-chart-arc', value: 'cryptos', selected: false },
    ],
    btnChartTypeGroupDetails: [
      { type: 1, name: 'MultiAxis' },
      { type: 2, name: 'MultiChart' },
    ],
    legends: [
      { name: 'Volume', color: constants.chartAxisColors.primary },
      { name: 'Market Cap', color: constants.chartAxisColors.secondary_3 },
      { name: 'BTC Dominance', color: constants.chartAxisColors.secondary_1 },
      { name: 'ETH Dominance', color: constants.chartAxisColors.red },
      { name: 'Cryptos', color: constants.chartAxisColors.darkyellow },
    ],
    volumeParams() {},
    marketCapParams() {},
  }),
  computed: {
    chartDisplayType: get('overview/chartDisplayType'),
    set: (value) => {
      this.chartDisplayType = value
    },
  },
  async beforeMount() {
    this.loading = false
    this.chartLoading = false
    this.viewType = 0
    await this.onFetchHistory()
    this.setChangeView()
    commit('overview/SET_VIEW', this.viewType)
  },
  methods: {
    ...call('overview', [
      'GET_HISTORY_GLOBAL_METRICS_REQUEST',
    ]),
    setChangeView() {
      this.groupBtnDetails = this.chartDisplayType

      if (this.chartDisplayType === 'marketcap') {
        this.onChangeView(1)
      } else if (this.chartDisplayType === 'btcdominance') {
        this.onChangeView(2)
      } else if (this.chartDisplayType === 'ethdominance') {
        this.onChangeView(3)
      } else if (this.chartDisplayType === 'cryptos') {
        this.onChangeView(4)
      } else {
        this.onChangeView(0)
      }
    },
    changeChartData() {
      this.simpleDataset = []
      if (this.chartType === 0) {
        this.setSingleDataSet(this.chartType)
      } else if (this.chartType === 1) {
        this.setMultiDataSet(true)
      } else if (this.chartType === 2) {
        this.setMultiDataSet(false)
      } else {
        this.setSingleDataSet(this.chartType)
      }
    },
    onChangeChartType(type) {
      this.chartType = type
      this.changeChartData()
      this.setChangeView()
    },
    onChangeDays(noOfDays) {
      this.common.daysGroup.forEach((o) => {
        if (o.name === noOfDays) {
          this.days = o.name
          o.selected = true
          this.selected_days = o
        } else {
          o.selected = false
        }
      })

      this.onFetchHistory()
    },
    onChangeDaysMenu(item) {
      this.common.daysGroup.forEach((o) => {
        this.days =  o.name === item.name ? o.name : this.days
        o.selected = o.name === item.name ? true : false
      })

      this.selected_days = item
      this.onFetchHistory()
    },
    setSingleDataSet(viewType) {
      if (this.chartType === 0) {
        console.log(o.dataLabel,'o.dataLabel')

        this.assets.forEach((o, idx) => {
          this.simpleDataset.push(
            {
              name: viewType === 0 ? o.name[0] : (viewType === 1) ? o.name[1] : (viewType === 2) ? o.name[2] : (viewType === 3) ? o.name[3] : o.name[4],
              symbol: viewType === 0 ? o.name[0] : (viewType === 1) ? o.name[1] : (viewType === 2) ? o.name[2] : (viewType === 3) ? o.name[3] : o.name[4],
              labels: o.dataLabel,
              data: viewType === 0 ? o.data : (viewType === 1) ? o.dataCap : (viewType === 2) ? o.btcdominance : (viewType === 3) ? o.ethdominance : o.cryptos,
              id: 'bcm',
              simple: true,
            })
        })
      } else {
        if (this.chartType === 1) {
          this.setMultiDataSet(true)
        } else {
          this.setMultiDataSet(false)
        }
      }
      commit('compare/SET_VIEW', viewType)
    },
    setMultiDataSet(isMultiAxis) {
      this.simpleDataset = []

      if (!isMultiAxis) {
        this.assets.forEach((o, idx) => {
          this.simpleDataset.push({
            name: o.name[0],
            symbol:  o.name[0],
            labels: o.dataLabel,
            data: o.data,
            id: 'volumn_' + 0,
            simple: false,
            color: constants.chartAxisColors.primary,
          }, {
            name: o.name[1],
            symbol: o.name[1],
            labels: o.dataLabel,
            data: o.dataCap,
            id: 'marketcap_' + 1,
            simple: false,
            color: constants.chartAxisColors.secondary_3,
          },{
            name: o.name[2],
            symbol: o.name[2],
            labels: o.dataLabel,
            data: o.btcdominance,
            id: 'btcdominance_' + 2,
            simple: false,
            color: constants.chartAxisColors.secondary_1,
          },
          {
            name: o.name[3],
            symbol: o.name[3],
            labels: o.dataLabel,
            data: o.ethdominance,
            id: 'ethdominance_' + 3,
            simple: false,
            color: constants.chartAxisColors.red,
          },
          {
            name: o.name[4],
            symbol: o.name[4],
            labels: o.dataLabel,
            data: o.cryptos,
            id: 'cryptos_' + 4,
            simple: false,
            color: constants.chartAxisColors.darkyellow,
          })
        })
      } else {
        this.getSelectedHistoryData()
      }
      commit('overview/SET_HISTORY_GLOBALMETRICS', this.onParse(this.simpleDataset))
    },
    getVolume() {
      this.simpleDataset.push({
        name: this.assets[0].name[0],
        symbol:  this.assets[0].name[0],
        labels: this.assets[0].dataLabel,
        data: this.assets[0].data,
        id: 'volume_' + 0,
        simple: false,
        color: constants.chartAxisColors.primary,
      })
    },
    getMarketCap() {
      this.simpleDataset.push({
        name: this.assets[0].name[1],
        symbol: this.assets[0].name[1],
        labels: this.assets[0].dataLabel,
        data: this.assets[0].dataCap,
        id: 'marketcap_' + 1,
        simple: false,
        color: constants.chartAxisColors.secondary_3,
      })
    },
    getETHDominance() {
      this.simpleDataset.push({
        name: this.assets[0].name[3],
        symbol: this.assets[0].name[3],
        labels: this.assets[0].dataLabel,
        data: this.assets[0].ethdominance,
        id: 'ethdominance_' + 3,
        simple: false,
        color: constants.chartAxisColors.red,
      })
    },
    getCryptos() {
      this.simpleDataset.push({
        name: this.assets[0].name[4],
        symbol: this.assets[0].name[4],
        labels: this.assets[0].dataLabel,
        data: this.assets[0].cryptos,
        id: 'cryptos_' + 4,
        simple: false,
        color: constants.chartAxisColors.darkyellow,
      })
    },
    getBTCDominance() {
      this.simpleDataset.push({
        name: this.assets[0].name[2],
        symbol: this.assets[0].name[2],
        labels: this.assets[0].dataLabel,
        data: this.assets[0].btcdominance,
        id: 'btcdominance_' + 2,
        simple: false,
        color: constants.chartAxisColors.secondary_1,
      })
    },
    onChangeView(viewType) {
      this.btnGroupDetails.forEach((o, index) => {
        if (index === viewType) {
          o.selected = true
          this.selected_tab = o
          this.viewType = viewType
        } else {
          o.selected = false
        }
      })

      this.simpleDataset = []
      this.setSingleDataSet(this.viewType)
    },
    onChangeViewMenu(item) {
      this.btnGroupDetails.forEach((o, index) => {
        if (o.name.toLowerCase() === item.name.toLowerCase()) {
          o.selected = true
          this.selected_tab = o
          this.viewType = index
        } else {
          o.selected = false
        }
      })

      this.simpleDataset = []
      this.setSingleDataSet(this.viewType)
    },
    async onFetchHistory() {

      const payload = {
        days: this.days,
      }

      this.chartLoading = true
      const res = await this.GET_HISTORY_GLOBAL_METRICS_REQUEST(payload)

      this.assets = [{
        id: 'bcm',
        data: res.data,
        dataLabel: res.label,
        dataCap: res.marketCap,
        btcdominance: res.btcDominance,
        ethdominance: res.ethDominance,
        cryptos: res.cryptos,
        name: res.legendName,
        simple: false,
      }]

      this.changeChartData()
      this.chartLoading = false
      commit('overview/SET_ASSETS', this.onParse(this.assets))
    },
    onParse(param) {
      return JSON.parse(JSON.stringify(param))
    },
    onLegendChange(value, index) {
      this.simpleDataset = []
      if (this.selected_legends && this.selected_legends.length > 0) {
        _.forEach(this.selected_legends, (o) => {
          if (o === 'Volume') {
            this.getVolume()
          } else if (o === 'Market Cap') {
            this.getMarketCap()
          } else if (o === 'BTC Dominance') {
            this.getBTCDominance()
          } else if (o === 'ETH Dominance') {
            this.getETHDominance()
          } else if (o === 'Cryptos') {
            this.getCryptos()
          } else {
            if (!(this.simpleDataset?.length > 0)) {
              this.setMultiDataSet()
            }
          }
        })
      } else {
        this.getSelectedHistoryData()
      }
    },
    getSelectedHistoryData() {
      if (this.chartDisplayType === 'marketcap') {
        this.selected_legends[0] = 'Market Cap'
        this.getMarketCap()
      } else if (this.chartDisplayType === 'btcdominance') {
        this.selected_legends[0] = 'BTC Dominance'
        this.getBTCDominance()
      } else if (this.chartDisplayType === 'ethdominance') {
        this.selected_legends[0] = 'ETH Dominance'
        this.getETHDominance()
      } else if (this.chartDisplayType === 'cryptos') {
        this.selected_legends[0] = 'Cryptos'
        this.getCryptos()
      } else {
        this.getVolume()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .theme--dark,.theme--light {
    &.v-card {
      &.v-sheet {
        background: $common_background
      }
    }
  }
</style>
